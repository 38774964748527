import { useEffect, useState } from 'react';
import { Dashboard } from '../structure/RenderDB';
import { LandlordDB } from '../private/Landlord';
import { ClientDB } from '../private/Client';
import { useNavigate } from 'react-router-dom';
import CompanyIcon from '../../assets/wlogo.svg';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import Spinner from 'react-bootstrap/Spinner';

function GrowComponent() {
  return <Spinner animation="grow" />;
}

const userComponents = {
    'admin': <Dashboard />,
    'landlord': <LandlordDB />,
    'client': <ClientDB />, 
};

const DefaultComponent = () =>{
    const navigate = useNavigate();

    return(
        <div className='container text-center' style={{ margin: '0 auto', maxWidth: '980px'}}>
            <h1 className='mt-2 mb-2'>WE ARE SORRY FOR THE INCONVENIENCY! </h1>
            <h5 className='mt-2 mb-2 lh-lg'>There is something wrong with our server. If the problems insists reach us through our <button onClick={()=>navigate('/contact')}>CONTACT US</button> page, thank you for understanding.</h5>
        </div>
    )
}

const LoadingComponent = () =>{
    return(
        <div className='container w-100 h-100'>
            <div className='row d-flex justify-content-center align-items-center'>
                <div className='col'>
                    <GrowComponent size='l'/>
                </div>
                <div className='col'>
                    <SwapHorizontalCircleIcon sx={{ fontSize: '5rem'}} />
                </div>
                <div className='col'>
                    <img src={CompanyIcon} alt='Ayopark Logo' style={{ width: '15rem', height: '15rem'}}/>
                </div>
            </div>
        </div>
    )
}

export const Account = () => {
    const [type, setType] = useState('');
    const [showComponent, setShowComponent] = useState(false);
    
    const fetchUser = async () => {
        const _user = localStorage.getItem('user');
        const _data = JSON.parse(_user);
        let _type;
        _type = _data.user;
        console.log('Type: '+_type.type)
        setType(_type.type)
    }
    
    useEffect(()=>{
        fetchUser();
        const timer = setTimeout(() => {
            setShowComponent(true);
        }, 5000);
      
        return () => clearTimeout(timer); 
    }, []);

    const ComponentToRender = userComponents[type] || <DefaultComponent />;
    
    return (
        <div>
            {showComponent ? ComponentToRender : <LoadingComponent />}
        </div>
    )
}