import { ContactForm } from '../structure/RenderService';
import Logo from '../../assets/wlogo.svg'

export const Contact = () => {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center mb-3 p-2'>
            <div style={{ maxWidth: '45rem' }}>
                <div className='w-100 text-center' style={{ height: '8rem'}}>
                    <img
                        className="img-fluid rounded d-block w-100 h-100"
                        loading='lazy'
                        src={Logo}
                        alt="ayopark logo"
                    />
                </div>
                <h5 className='text-center text-uppercase'>
                    Contact Ayopark
                </h5>
                <ContactForm />
            </div>
        </div>
    )
}