import { useNavigate } from 'react-router-dom';
import wlogo from '../../assets/wlogo.svg';
import { servicesD } from '../../utils/service.data';
import { SubscriptionD } from '../../utils/company.data';
import { SubscriptionCard, ServiceCard, TextCarousel} from '../services/subscription.plan';

function AboutSection(){
  const bgColors = [
    'parking_001',
    'parking_002',
    'parking_003'
  ];
  let bgColorsLen = bgColors.length;
  let randomNum = Math.floor(Math.random() * bgColorsLen);
  let aboutImg = bgColors[randomNum];

  return(
      <section class="py-3 py-md-5">
        <div class="container">
          <div class="row gy-3 gy-md-4 gy-lg-0 align-items-lg-center">
            <div class="col-12 col-lg-6 col-xl-5">
              <img
                className="img-fluid rounded"
                loading='lazy'
                src={`/${aboutImg}.jpg`}
                alt="About Image"
              />
            </div>
            <div class="col-12 col-lg-6 col-xl-7">
              <div class="row justify-content-xl-center">
                <div class="col-12 col-xl-11">
                  <h2 class="mb-3">Who Are We?</h2>
                  <p class="lead fs-4 mb-3">
                    We assist private car park owners in establishing highly efficient parking systems using ANPR technology. Our goal is to help these owners generate passive income through their parking spaces
                  </p>
                  <p class="mb-5">
                    Ayopark operates within an intricate landscape, managing parking facilities on behalf of landlords in the UK, We believe in collaboration, innovation, and customer satisfaction. We are always looking for new ways to improve our products and services.
                  </p>
                  <div class="row gy-4 gy-md-0 gx-xxl-5X">
                    <div class="col-12 col-md-6">
                      <div class="d-flex">
                        <div class="me-4 text-primary">
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FFC61A" className="bi bi-gear-fill" viewBox="0 0 16 16">
                            <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                          </svg>
                        </div>
                        <div>
                          <h2 class="h4 mb-3">Adaptable Parking Solutions</h2>
                          <p class="text-secondary mb-0">We are developing a digital approach that enhances parking management across all platforms.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="d-flex">
                        <div class="me-4 text-primary">
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#FFC61A" className="bi bi-fire" viewBox="0 0 16 16">
                            <path d="M8 16c3.314 0 6-2 6-5.5 0-1.5-.5-4-2.5-6 .25 1.5-1.25 2-1.25 2C11 4 9 .5 6 0c.357 2 .5 4-2 6-1.25 1-2 2.729-2 4.5C2 14 4.686 16 8 16Zm0-1c-1.657 0-3-1-3-2.75 0-.75.25-2 1.25-3C6.125 10 7 10.5 7 10.5c-.375-1.25.5-3.25 2-3.5-.179 1-.25 2 1 3 .625.5 1 1.364 1 2.25C11 14 9.657 15 8 15Z" />
                          </svg>
                        </div>
                        <div>
                          <h2 class="h4 mb-3">Electronic Parking Management</h2>
                          <p class="text-secondary mb-0">We are committed to advancing parking enforcement by integrating fundamental strategies with sophisticated solutions.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

function SolutionSection(){
  return(
    <div class="container">
      <section class="section pt-0">
          <div class="container">
              <div class="row gy-4 justify-content-center">
                  <div class="col-sm-6 col-md-6 col-lg-4">
                      <div class="card">
                          <div class="card-body d-flex">
                              <div class="icon-lg bg-warning rounded-3 text-white">
                                <i class="pi pi-chart-line" 
                                  style={{ 
                                    height: '100%',
                                    fontSize: '3rem', 
                                    display: 'inline-flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    margin: 'auto .5rem'}}></i>
                              </div>
                              <div class="ps-3 col">
                                  <h5 class="h6 mb-2"><a class="stretched-link text-reset" href="#">
                                      Improved Traffic Flow
                                    </a>
                                  </h5>
                                  <p class="m-0">
                                    Streamline your property traffic flow with our advanced parking solutions. Our ANPR technology ensures smooth vehicle movement, reducing congestion and enhancing overall accessibility.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4">
                      <div class="card">
                          <div class="card-body d-flex">
                              <div class="icon-lg bg-warning rounded-3 text-white"><i class="pi pi-thumbs-up" 
                              style={{ 
                                    height: '100%',
                                    fontSize: '2rem', 
                                    display: 'inline-flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    margin: 'auto .5rem'}}></i></div>
                              <div class="ps-3 col">
                                  <h5 class="h6 mb-2"><a class="stretched-link text-reset" href="#">
                                    Increased Safety</a>
                                  </h5>
                                  <p class="m-0">
                                    Prioritize safety with our comprehensive parking management. From well-lit areas to advanced surveillance, we create a secure environment for both vehicles and visitors.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4">
                      <div class="card">
                          <div class="card-body d-flex">
                              <div class="icon-lg bg-warning rounded-3 text-white"><i class="pi pi-credit-card" style={{ 
                                    height: '100%',
                                    fontSize: '2rem', 
                                    display: 'inline-flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    margin: 'auto .5rem'}}></i></div>
                              <div class="ps-3 col">
                                  <h5 class="h6 mb-2"><a class="stretched-link text-reset" href="#">
                                    Generate Revenue</a>
                                  </h5>
                                  <p class="m-0">
                                    Monetize your parking space efficiently. Our solutions optimize operations, maximizing income potential while ensuring a fair and seamless experience for motorists.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-4">
                      <div class="card">
                          <div class="card-body d-flex">
                              <div class="icon-lg bg-warning rounded-3 text-white"><i class="pi pi-globe" 
                              style={{ 
                                    height: '100%',
                                    fontSize: '2rem', 
                                    display: 'inline-flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    margin: 'auto .5rem'}}></i></div>
                              <div class="ps-3 col">
                                  <h5 class="h6 mb-2"><a class="stretched-link text-reset" href="#">
                                    Charity Donation</a>
                                  </h5>
                                  <p class="m-0">
                                    Contribute to our chosen charity with every paid parking charge notice. Our unique approach allows landlords to make a positive impact on the community through philanthropic parking management.
                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
    </div>
  )
}

const LandingPage = () => {
  const navigate = useNavigate();

  const handleContact = () =>{
    setTimeout(() => {
      navigate('/contact');
    }, 1000);
  }

  return (
    <div className="container-fluid">
      <div className="row mt-5">
        <div className="col-12">
          <div style={{
            height: '8rem'
          }}>
            <img
              className="d-block w-100 h-100"
              src={wlogo}
              alt="ayopark company logo"
            />
          </div>
          <div className="d-flex flex-column align-items-center ml-1 mr-1">
            <h1 className="display-4 font-weight-bold text-center" style={{
              fontSize: '2rem'
            }}>
              Ayopark Enforcement
            </h1>
            <p className="lead text-center lh-lg">
              Turn your car park into a revenue generating opportunity with up to £50 profit share per paid parking charge.
            </p>
            <button className="btn btn-warning mt-3" onClick={handleContact}>Contact Us</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Home = () => {

    return (
        <div className='HomeScreenWrapper' style={{
          width: '100vw'
        }}>
            <LandingPage />
            <AboutSection />
            <div>
              <h3 className='m-2 p-2 fs-1 opacity-40' style={{
                width: '20rem',
                fontStyle: 'italic',
                borderBottom: '.5rem solid #f1b041'
              }}>Why Are you Here?</h3>
              <ServiceCard Arr={servicesD} />
            </div>
            <div>
              <h3 className='m-2 p-2 fs-1 opacity-40' style={{
                width: '20rem',
                fontStyle: 'italic',
                borderBottom: '.5rem solid #f1b041'
              }}>A Solution To Your Problems</h3>
              <p className='m-1 fs-4 opacity-75'>
                Optimize The Management Of Parking Violations, Streamline Enforcement Processes And Enhance Overall Operational Efficiency
              </p>
              <SolutionSection />
            </div>
            <div>
              <h3 className='m-2 p-2 fs-1 opacity-40' style={{
                width: '20rem',
                fontStyle: 'italic',
                borderBottom: '.5rem solid #f1b041'
              }}>Guided Services and Management</h3>
              <SubscriptionCard Arr={SubscriptionD} />
            </div>
            <div className='m-1 p-1'>
              <h3 className='m-2 p-2 fs-1 opacity-40' style={{
                width: '20rem',
                fontStyle: 'italic',
                borderBottom: '.5rem solid #f1b041'
              }}>Benefits of working with our team</h3>
              <TextCarousel />
              <p className='text-center text-warning'>
                The landlord will not receive payment in the event of a successful appeal by the motorist or if payment for the parking charge notice is not received.</p>
            </div>
        </div>
    )
}