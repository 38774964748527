import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { RenderHeader } from '../components/structure/Header';
import { RenderFooter } from '../components/structure/Footer';
import { RenderRoutes } from '../components/structure/RenderNavigation';
import { ChatBot } from '../components/services/chatbot';

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

const AuthContext = createContext();
export const AuthData = () => useContext(AuthContext);

export const AuthWrapper = () => {
  const [user, setUser] = useState({
    name: 'guest',
    type: '',
    email: '',
    isAuthenticated: false,
  });

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser({ ...parsedUser.user, isAuthenticated: true });
      axios.defaults.headers.common['Authorization'] = `Bearer ${parsedUser.token}`;
    }
  }, []);

  const login = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post('/api/user/login', { email, password })
        .then((response) => {
          const { user: userData, token } = response.data;
          if (!token) return reject('Something wrong with server!');
          setUser({ ...userData, isAuthenticated: true });
          localStorage.setItem(
            'user',
            JSON.stringify({
              user: userData,
              token,
            })
          );
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          resolve('success');
        })
        .catch((err) => {
          console.log(err);
          reject('Incorrect email or password');
        });
    });
  };

  const logout = () => {
    localStorage.removeItem('user');
    setUser({
      name: 'guest',
      type: '',
      email: '',
      isAuthenticated: false,
    });
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <RenderHeader />
      <RenderRoutes />
      <ChatBot />
      <RenderFooter />
    </AuthContext.Provider>
  );
};