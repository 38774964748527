import { About } from '../pages/About'
import { Appeal } from '../pages/Appeal'
import { Contact } from '../pages/Contact'
import { Payment } from '../services/payment'
import { Home } from '../pages/Home'
import { Login } from '../pages/Login'
import { Private } from '../pages/Private'
import { Account } from '../pages/Account'
import { Policy } from '../pages/Policy'
import { Process } from '../pages/Process'
import { Complaint } from '../pages/Complaint'
import { Condition } from '../pages/Condition'
import { Notice } from '../private/notice/Notice'
import { NoticeForm as NForm } from '../private/notice/Form'
import { ContactDB } from '../private/contact/index'
import { AppealDB } from '../private/appeal/index'
import { NoticeDB } from '../private/notice/index'
import { UserDB } from '../private/user/index'
import { VehicleDB } from '../private/vehicle/index'
import { LocationDB } from '../private/location/index'
import { ClientDB } from '../private/Client'
import { LandlordDB } from '../private/Landlord'

export const nav = [
    /************************* company services **************************/
    { path: "/", name: "Home", element: <Home />, isMenu: true, isPrivate: false, img: 'pi-home'},
    { path: "/about", name: "FQA", element: <About />, isMenu: true, isPrivate: false, img: 'pi-question-circle'},
    { path: "/contact", name: "Contact", element: <Contact />, isMenu: true, isPrivate: false, img: 'pi-envelope'},
    { path: "/appeal", name: "Appeal", element: <Appeal />, isMenu: true, isPrivate: false, img: 'pi-info-circle'},
    { path: "/payment", name: "Payment", element: <Payment />, isMenu: true, isPrivate: false, img: 'pi-credit-card'},
    { path: "/login", name: "Login", element: <Login />, isMenu: false, isPrivate: false, img: 'pi-sign-in'},
    { path: "/policy", name: "Privacy Policy", element: <Policy />, isMenu: false, isPrivate: false, img: 'pi-info-circle'},
    { path: "/condition", name: "Terms & Conditions", element: <Condition />, isMenu: false, isPrivate: false, img: 'pi-info-circle'},
    { path: "/process", name: "Appeal Process", element: <Process />, isMenu: false, isPrivate: false, img: 'pi-info-circle'},
    { path: "/complaint", name: "Complaint Process", element: <Complaint />, isMenu: false, isPrivate: false, img: 'pi-info-circle'},
    /************************* client services **************************/
    { path: "/private", name: "Dashboard", element: <Private />, isMenu: false, isPrivate: true},
    { path: "/account", name: "Account", element: <Account />, isMenu: false, isPrivate: true},
    { path: "account/notice", name: "PCN Information", element: <NoticeDB />, isMenu: false, isPrivate: true},
    { path: "/account/contact", name: "Contact Information", element: <ContactDB />, isMenu: false, isPrivate: true},
    { path: "/account/appeal", name: "Appeal Information", element: <AppealDB />, isMenu: false, isPrivate: true},
    { path: "/account/user", name: "User Information", element: <UserDB />, isMenu: false, isPrivate: true},
    { path: "/account/notice/:id", name: "PCN", element: <Notice />, isMenu: false, isPrivate: false},
    { path: "/account/admin/notice", name: "Create PCN", element: <NForm />, isMenu: false, isPrivate: false},
    { path: "/account/admin/vehicle", name: "Vehicle Panel", element: <VehicleDB />, isMenu: false, isPrivate: false},
    { path: "/account/landlord/location", name: "Location Panel", element: <LocationDB />, isMenu: false, isPrivate: false},
    { path: "/account/landlord", name: "Landlord", element: <LandlordDB />, isMenu: false, isPrivate: false},
    { path: "/account/motorist", name: "Motorist", element: <ClientDB />, isMenu: false, isPrivate: false},
]