import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card } from 'react-bootstrap';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export function ContactForm() {
  const [ file, setFile] = useState(null);
  const [ formData, setFormData ] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    address: '',
    file: file
  });
  const [ feedback, setFeedback ] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setFormData({...formData, [name]: value});
  }

  const handleFile = (e) =>{
    setFile(e.target.files[0]);
  }

  const contactAPI = async () =>{
    console.log('Images: '+file);
    axios.post(`/api/contact`, formData)
      .then((response)=>{
          setFeedback("Contact has been Submitted!");
      })
      .catch((err)=>{
          setFeedback("Problem with network! Try again later.");
          setTimeout(()=>{
            setFeedback("");
          }, 5000)
      })
  }

  const handleSubmit = (e) =>{
    e.preventDefault();
    contactAPI();
  }

  return (
    <Form className="m-1 p-1 form-container">
      {/* phone input */}
      <Form.Group controlId="formBasicText" className="mb-3">
        <Form.Label>Name:</Form.Label>
        <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter your full name" />
      </Form.Group>
      {/* email input */}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email:</Form.Label>
        <Form.Control type="email" name='email' onChange={handleChange} placeholder="Enter your email" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      {/* phone input */}
      <Form.Group controlId="formBasicText" className="mb-3">
        <Form.Label>Phone:</Form.Label>
        <Form.Control type="text" name='phone' onChange={handleChange} placeholder="Enter your phone number" />
      </Form.Group>
      {/* address input */}
      <Form.Group controlId="formBasicAddress" className="mb-3">
        <Form.Label>Address:</Form.Label>
        <Form.Control type="text" name='address' onChange={handleChange} placeholder="Enter your physical address" />
      </Form.Group>
      {/* message textarea */}
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Message:</Form.Label>
        <Form.Control as="textarea" name='message' onChange={handleChange} rows={3} placeholder='Enter your message'/>
      </Form.Group>
      {/* file input */}
      <Form.Group controlId="formFileMultiple" className="mb-3">
        <Form.Label>Choose File:</Form.Label>
        <Form.Control type="file" name='file' onChange={handleFile} multiple />
      </Form.Group>
      {/* user feedback */}
      <Form.Group className='text-center m-2'>
        <Form.Text className="text-warning" style={{
          fontSize: '1.25rem'
        }}>
          { feedback !== ""? feedback: ""}
        </Form.Text>
      </Form.Group>
      {/* submit contact btn */}
      <div className='text-center'>
        <Button variant="warning" type="submit" className='m-1 p-2 w-100' onClick={handleSubmit}>
          Contact Us
        </Button>
      </div>
    </Form>
  );
}

export function AppealForm() {
  const [ formData, setFormData ] = useState({
    name: '',
    email: '',
    phone: '',
    role: '',
    notice: '',
    vehicle: '',
    message: '',
    file: null
  })
  const [ feedback, setFeedback ] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setFormData({...formData, [name]: value});
  }
  
  const appealAPI = async () =>{
    axios.post(`/api/appeal`, formData)
      .then((response)=>{
          setFormData({
            name: '', email: '', phone: '', role: '', notice: '', vehicle: '', message: '', file: null 
          });
          setFeedback("Your Appeal has been submitted!")
          setTimeout(()=>{
            navigate('/')
          }, 9000)
      })
      .catch((err)=>{        
          setFeedback("Problem with network! Try again later.");
          setTimeout(()=>{
            setFeedback("");
          }, 15000)
      })
  }
  
  const handleSubmit = (e) =>{
    e.preventDefault();
    appealAPI();
  }

  return (
      <Form className="m-1 p-1 form-container">
        {/* form title */}
        <div className='m-2 p-1 text-center'>
          <h3>Parking Charge Notice (PCN) Appeal</h3>
        </div>
      {/* name input */}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Name:</Form.Label>
        <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter your name" />
      </Form.Group>
      {/* address input */}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Address:</Form.Label>
        <Form.Control type="text" name='address' onChange={handleChange} placeholder="Enter your address" />
      </Form.Group>
      {/* role of user */}
      <Form.Group className='mb-3'>
        <Form.Label>I am the:</Form.Label>
        <Form.Select aria-label="select role input" name='role' onChange={handleChange}>
          <option value="driver">driver</option>
          <option value="keeper">keeper</option>
          <option value="hirer">hirer</option>
        </Form.Select>
      </Form.Group>
      {/* phone input */}
      <Form.Group controlId="formBasicText" className="mb-3">
        <Form.Label>Phone:</Form.Label>
        <Form.Control type="text" name='phone' onChange={handleChange} placeholder="Enter your phone number" />
      </Form.Group>
      {/* Parking Charge Notice */}
      <Form.Group controlId="formBasicText" className="mb-3">
        <Form.Label>Parking Charge Notice ID:</Form.Label>
        <Form.Control type="text" name='PCNnumber' onChange={handleChange} placeholder="Enter your parking charge notice" />
      </Form.Group>
      {/* Vehicle Registration Plate */}
      <Form.Group controlId="formBasicText" className="mb-3">
        <Form.Label>Vehicle Registration:</Form.Label>
        <Form.Control type="text" name='VRNnumber' onChange={handleChange} placeholder="Enter your vehicle number plate" />
      </Form.Group>
      {/* message textarea */}
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label>Reason For Appeal:</Form.Label>
        <Form.Control as="textarea" name='message' onChange={handleChange} rows={3} placeholder='Enter your message'/>
      </Form.Group>
      {/* file input */}
      <Form.Group controlId="formFileMultiple" className="mb-3">
        <Form.Label>Choose File:</Form.Label>
        <Form.Control type="file" name='file' onChange={handleChange} multiple />
      </Form.Group>
      {/* user feedback */}
      <Form.Group className='text-center m-2'>
        <Form.Text className="text-warning" style={{
          fontSize: '1.25rem'
        }}>
          { feedback !== ""? feedback: ""}
        </Form.Text>
      </Form.Group>
      {/* submit contact btn */}
      <div className='text-center'>
        <Button variant="warning" type="submit" className='m-1 p-2 w-100 text-uppercase' onClick={handleSubmit}>
          Submit Appeal
        </Button>
      </div>
    </Form>
  );
}

export function ParkingChargeNoticeForm(){
  const [ formData, setFormData ] = useState({
    name: "",
    email: "",
    phone: "",
    amount: 50, 
    role: "", 
    address: "", 
    location: "", 
    vehicle: "", 
    arrivingTime: "", 
    arrivingDate: "", 
    departureTime: "", 
    departureDate: "",
    file: null
  });
  const [ feedback, setFeedback ] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setFormData({...formData, [name]: value});
  }

  const noticeAPI = async () =>{
    axios.post(`/api/notice`, formData)
      .then((response)=>{
          console.log(`PCN Submit Response: ${response}`)
          setFeedback("Your PCN has been created!");
          setTimeout(()=>{
            navigate('/account/notice')
          }, 5000)
      })
      .catch((err)=>{
          setFeedback("Problem with network! Try again later.");
          setTimeout(()=>{
            setFeedback("");
          }, 5000)
      })
  }
  
  const handleSubmit = (e) =>{
    e.preventDefault();
    if(formData.amount == "" | formData.vehicle == "" | formData.role == "") return setFeedback("Please fill All inputs!");
    noticeAPI();
  }

  return (
    <Form className="m-1 p-2 form-container">
      {/* form title */}
      <div className='m-2 p-1 text-center'>
        <h5 className='mb-2'>Parking Charge Notice (PCN) Form</h5>
      </div>
      {/* name input */}
      <Form.Group controlId="formBasicText" className="mb-3">
        <Form.Label>Name:</Form.Label>
        <Form.Control type="text" name='name' onChange={handleChange} placeholder="Enter client name" />
      </Form.Group>
      {/* email input */}
      <Form.Group controlId="formBasicEmail" className="mb-3">
        <Form.Label>Email:</Form.Label>
        <Form.Control type="email" name='email' onChange={handleChange} placeholder="Enter client email" />
      </Form.Group>
      {/* phone input */}
      <Form.Group controlId="formBasicPhone" className="mb-3">
        <Form.Label>Phone:</Form.Label>
        <Form.Control type="text" name='phone' onChange={handleChange} placeholder="Enter client phone number" />
      </Form.Group>
      {/* amount input */}
      <Form.Group controlId="formBasicAmount" className="mb-3">
        <Form.Label>Charge:</Form.Label>
        <Form.Control type="number" name='amount' onChange={handleChange} placeholder="Enter client PCN charge fee" />
      </Form.Group>
      {/* address input */}
      <Form.Group controlId="formBasicAddress" className="mb-3">
        <Form.Label>Address:</Form.Label>
        <Form.Control type="text" name='address' onChange={handleChange} placeholder="Enter client physical address" />
      </Form.Group>
      {/* location input */}
      <Form.Group controlId="formBasicLocation" className="mb-3">
        <Form.Label>Location:</Form.Label>
        <Form.Control type="text" name='location' onChange={handleChange} placeholder="Enter client carpark location" />
      </Form.Group>
      {/* role input */}
      <Form.Group className='mb-3'>
        <Form.Label>I am the:</Form.Label>
        <Form.Select aria-label="select role input" name='role' onChange={handleChange}>
          <option value="driver">driver</option>
          <option value="keeper">keeper</option>
          <option value="hirer">hirer</option>
        </Form.Select>
      </Form.Group>
      {/*  Vehicle Registration */}
      <Form.Group controlId="formBasicVehicle" className="mb-3">
        <Form.Label>Vehicle:</Form.Label>
        <Form.Control type="text" name='vehicle' onChange={handleChange} placeholder="Enter your vehicle registration number" />
      </Form.Group>
      {/* Arriving & Departing Time */}
      <Form.Group controlId="formBasicTime" className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <Form.Label>Arrived Time:</Form.Label>
          <Form.Control type="time" name="arrivingTime" onChange={handleChange} placeholder="Enter vehicle arrived time" />
        </div>
        <div>
          <Form.Label>Departed Time:</Form.Label>
          <Form.Control type="time" name="departureTime" onChange={handleChange} placeholder="Enter vehicle departed time" />
        </div>
      </Form.Group>
      {/* Arriving & Departing Date */}
      <Form.Group controlId="formBasicDate" className="mb-3 d-flex justify-content-between align-items-center">
        <div>
          <Form.Label>Arrived Date:</Form.Label>
          <Form.Control type="date" name="arrivingDate" onChange={handleChange} placeholder="Enter vehicle arrived date" />
        </div>
        <div>
          <Form.Label>Departed Date:</Form.Label>
          <Form.Control type="date" name="departureDate" onChange={handleChange} placeholder="Enter vehicle departed date" />
        </div>
      </Form.Group>
      {/* file input */}
      <Form.Group controlId="formFileMultiple" className="mb-3">
        <Form.Label>Choose File:</Form.Label>
        <Form.Control type="file" name='file' onChange={handleChange} multiple />
      </Form.Group>
      {/* user feedback */}
      <Form.Group className='text-center m-2'>
        <Form.Text className="text-warning" style={{
          fontSize: '1.25rem'
        }}>
          { feedback !== ""? feedback: ""}
        </Form.Text>
      </Form.Group>
      {/* submit contact btn */}
      <div className='text-center'>
        <Button variant="warning" type="submit" className='w-100' onClick={handleSubmit}>
          SUBMIT PCN
        </Button>
      </div>
    </Form>
  );
}

export function PropertyManager(){
  const [owner, setOwner] = useState('');
  const [location, setLocation] = useState('');
  const [file, setFile] = useState(null);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    // Fetch properties on component mount
    axios.get('/property')
      .then(response =>setProperties(response.data))
      .catch(error => console.error('Error fetching properties:', error));
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('owner', owner);
    formData.append('location', location);
    formData.append('file', file);

    axios.post('/api/property/upload', formData)
      .then(response => {
        console.log('Property uploaded successfully:', response.data);
        setProperties([...properties, response.data]);
      })
      .catch(error => console.error('Error uploading property:', error));
  };

  return (
    <div>
      <h1>Property Manager</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Owner:</label>
          <input type="text" value={owner} onChange={(e) => setOwner(e.target.value)} required />
        </div>
        <div>
          <label>Location:</label>
          <input type="text" value={location} onChange={(e) => setLocation(e.target.value)} required />
        </div>
        <div>
          <label>File:</label>
          <input type="file" onChange={handleFileChange} required />
        </div>
        <button type="submit">Upload Property</button>
      </form>
      <h2>Uploaded Properties</h2>
      <div>
        {properties.map(property => (
          <div key={property.id}>
            <h3>{property.owner}</h3>
            <p>{property.location}</p>
            <img src={`/property/image/1`} alt="Property" />
          </div>
        ))}
      </div>
    </div>
  );
};

export const PropertyGallery = () => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    // Fetch properties on component mount
    axios.get('/api/property')
      .then(response => setProperties(response.data))
      .catch(error => console.error('Error fetching properties:', error));
  }, []);

  return (
    <Container>
      <h1 className="my-4">Property Gallery</h1>
      <Row>
        {properties.map(property => (
          <Col key={property.id} xs={12} md={6} lg={4} className="mb-4">
            <Card>
              <Card.Img variant="top" src={`/property/image/${property.id}`} alt="Property" />
              <Card.Body>
                <Card.Title>{property.owner}</Card.Title>
                <Card.Text>{property.location}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};