import { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { LoginUser, RegisterUser } from '../structure/RenderRegister';
import Wlogo from '../../assets/wlogo.svg';

function Loader() {
  return (
    <>
      <Spinner animation="border" size="sm" />
      <Spinner animation="border" />
      <Spinner animation="grow" size="sm" />
      <Spinner animation="grow" />
    </>
  );
}

export const Login = () => {
    const [activeButton, setActiveButton] = useState(true);
    const [feedback, setFeedback] = useState(false);

    const handleActive = (e) => {
        if(activeButton){
            setFeedback(true);
            setTimeout(()=>{
                setFeedback(false);
                setActiveButton(false);
            }, 5000)
        }else{
            setFeedback(true);
            setTimeout(()=>{
                setFeedback(false);
                setActiveButton(true);
            }, 5000)
        }
    }

    return (
        <div className='login-wrapper d-flex flex-column justify-content-center align-items-center'>
            <div className='w-100 text-center' style={{height: '8rem'}}>
                <img
                    className="img-fluid rounded d-block w-100 h-100"
                    loading='lazy'
                    src={Wlogo}
                    alt="Ayopark Logo"
                />
            </div>
            <div>
                {
                    activeButton? <LoginUser /> : <RegisterUser />
                }
                <div className='mb-5'>
                    {
                        activeButton? <a className='h6 text-warning' style={{
                            marginLeft: '.5rem',
                            padding: '.5rem auto',
                            fontSize: '.75rem',
                            cursor: 'pointer'
                        }} onClick={handleActive}>Sign Up Instead?{feedback?<Loader/>:""}</a> : <a className='h6 text-warning' style={{
                            marginLeft: '.5rem',
                            padding: '.5rem auto',
                            fontSize: '.75rem',
                            cursor: 'pointer'
                        }} onClick={handleActive}>Sign Login Instead?{feedback?<Loader/>:""}</a>
                    }
                </div>
            </div>
        </div>
    )
}