import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthData } from '../../auth/AuthWrapper';
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

export function LoginUser() {
  const [ formData, setFormData ] = useState({
    email: '',
    password: ''
  });
  const [ feedback, setFeedback ] = useState("");
  const [ islogging, setIsLogging ] = useState(false);
  const navigate = useNavigate();
  const { login } = AuthData();

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setFormData({...formData, [name]: value});
  }

  function Loader() {
    return (
      <>
        <Spinner animation="grow" variant="success" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="info" />
        <Spinner animation="grow" variant="light" />
      </>
    );
  }

  const loginAPI = async () => {
    try{
      await login(formData.email, formData.password);
        setIsLogging(true);
        setTimeout(()=>{
          setFormData({email: '', password: ''});
          navigate('/account');
        }, 3000)
    }catch(err){
      setFormData({email: '', password: ''});
      setFeedback("Problem with the network! Try again later.");
      setTimeout(()=>{
        setFeedback("");
      }, 5000)
    }
  }
  
  const handleSubmit = (e) =>{
    e.preventDefault();
    if(formData.email === "" || formData.password === ""){
      return setFeedback("Enter your login details!");
    }
    loginAPI();
  }

  return (
    <Form className='m-1 p-1 form-container'>
      <div>
        <h3 className='m-1 p-2 text-center text-uppercase'>ayopark login client</h3>
      </div>
      {/* email input */}
      <Form.Group className="mb-2" controlId="formBasicEmail">
        <Form.Label>Email:</Form.Label>
        <Form.Control type="email" name='email' onChange={handleChange} placeholder="Enter your email" />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>
      {/* password input */}
      <Form.Group className="mb-2" controlId="formBasicPassword">
        <Form.Label>Password:</Form.Label>
        <Form.Control type="password" name='password' onChange={handleChange} placeholder="Enter your password" />
      </Form.Group>
      {/* remember password input */}
      <Form.Group className="mb-2" controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Remember Password" className='text-warning' style={{
          fontSize: '.75rem'
        }} />
      </Form.Group>
      {/* user feedback */}
      <Form.Group className='text-center m-2'>
        <Form.Text className="text-warning" style={{
          fontSize: '1.25rem'
        }}>
          {islogging? <Loader /> : <span>{feedback}</span> }
        </Form.Text>
      </Form.Group>
      {/* login button */}
      <div className='text-center'>
        <Button variant="warning" onClick={handleSubmit} type="submit" className='w-100'>
          Login
        </Button>
      </div>
    </Form>
  );
}

export function RegisterUser() {
  const [ formData, setFormData ] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    password: '',
    type: ''
  });
  const navigate = useNavigate();
  const [isMatch, setIsMatch] = useState(true);
  const [ feedback, setFeedback ] = useState("");
  const [ msg, setMsg] = useState('');

  const handleChange = (e) =>{
    const { name, value } = e.target;
    setFormData({...formData, [name]: value});
  }

  const registerAPI = async () =>{
    axios.post(`/api/user/register`, formData)
      .then((response)=>{
        setFormData({ firstname: '', lastname: '', email: '', phone: '', password: '', type: ''});
        setFeedback("Account registration successful!")
        setTimeout(()=>{
          navigate('/login')
        }, 9000)
      })
      .catch((err)=>{
        setFormData({ firstname: '', lastname: '', email: '', phone: '', password: '', type: ''});
        setFeedback("Problem with network! Try again later.");
        setTimeout(()=>{
          setFeedback("");
        }, 15000)
      })
  }
  
  const handleSubmit = (e) =>{
    e.preventDefault();
    if(isMatch && formData.type !== "") return registerAPI();
    setMsg("Your password does not match!");
  }

  const handleConfirmPasswordChange = (e) => {
    setIsMatch(e.target.value === formData.password);
  }

  return (
    <Form className='m-1 p-2 form-container'>
      <div>
        <h3 className='m-1 p-2 text-center text-uppercase'>ayopark register client</h3>
      </div>
      {/* firstname input */}
      <Form.Group className="mb-3" controlId="formBasicFirst">
        <Form.Label>First Name: </Form.Label>
        <Form.Control type="text" name="firstname" onChange={handleChange} placeholder="Enter your firstname" />
      </Form.Group>
      {/* lastname input */}
      <Form.Group className="mb-3" controlId="formBasicLast">
        <Form.Label>Last Name: </Form.Label>
        <Form.Control type="lastname" name="lastname" onChange={handleChange} placeholder="Enter your lastname" />
      </Form.Group>
      {/* phone input */}
      <Form.Group className="mb-3" controlId="formBasicPhone">
        <Form.Label>Phone Number: </Form.Label>
        <Form.Control type="text" name="phone" onChange={handleChange} placeholder="Enter your phone number" required />
      </Form.Group>
      {/* email input */}
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email Address:</Form.Label>
        <Form.Control type="email" name="email" onChange={handleChange} placeholder="Enter your email" required />
      </Form.Group>
      {/* role of user */}
      <Form.Group className="mb-3" controlId="formBasicSelect">
        <Form.Label>Type:</Form.Label>
        <Form.Select aria-label="select role input" name='type' onChange={handleChange} required>
          <option>...please choose user type...</option>
          <option value="landlord">landlord (i.e car park owner)</option>
          <option value="client">client (i.e PCN debt recovery )</option>
        </Form.Select>
      </Form.Group>
      {/* password input */}
      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password:</Form.Label>
        <Form.Control type="password" name="password" onChange={handleChange} placeholder="Enter your password" required />
      </Form.Group>
      {/* confirm password input */}
      <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
        <Form.Label>Confirm Password:</Form.Label>
        <Form.Control type="password" name="confirmPassword" onChange={handleConfirmPasswordChange} placeholder="Enter your password confirmation" required />
        <Form.Text className="text-muted">
          {!isMatch && <p className='text-center m-1 p-1 text-light'>Passwords do not match!</p>}
        </Form.Text>
      </Form.Group>
      {/* user feedback */}
      <Form.Group className='text-center m-2'>
        <Form.Text className="text-warning" style={{
          fontSize: '1.25rem'
        }}>
          { feedback !== ""? feedback: ""}
        </Form.Text>
      </Form.Group>
      <div className='text-center'>
      <Form.Group className="text-center" controlId="formBasicButton">
        <Button variant="warning" className='w-100' onClick={handleSubmit} type="submit">
          Register
        </Button>
        <Form.Text className="text-muted">
          {msg && <p className='text-center m-1 p-1 text-warning' style={{ fontSize: '1.5rem'}}>{msg}</p>}
        </Form.Text>
      </Form.Group>
      </div>
    </Form>
  );
}